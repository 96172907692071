var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"job-container"},[_c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-wrap mt-20 p-20",staticStyle:{"border-radius":"8px 8px 0px 0px"}},[_c('el-input',{staticClass:"mr-10 input-with-select",attrs:{"placeholder":"请输入关键字搜索"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.search}},[_vm._v("搜索")])],1)]),_c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-wrap flex-column pr-20 pl-20 pb-20 border-bottom"},[_c('div',{class:("" + (_vm.isExpand ? 'condition-section' : 'condition-section-hide'))},[_c('div',{staticClass:"job-condition"},[_c('span',{staticClass:"job-condition-title"},[_vm._v("薪资要求:")]),_c('div',{staticClass:"job-condition-wrap"},[_c('span',{staticClass:"job-condition-item",class:{ checked: _vm.currentSalary === '' },on:{"click":function($event){return _vm.handleChange(0, '')}}},[_vm._v("全部")]),_vm._l((_vm.SalaryOptions),function(ref){
var label = ref.label;
var value = ref.value;
return _c('span',{key:value,staticClass:"job-condition-item",class:{
                checked: _vm.currentSalary !== '' && _vm.currentSalary == value
              },on:{"click":function($event){return _vm.handleChange(0, value)}}},[_vm._v(_vm._s(label))])})],2)]),_c('div',{staticClass:"job-condition"},[_c('span',{staticClass:"job-condition-title "},[_vm._v("工作经验:")]),_c('div',{staticClass:"job-condition-wrap "},[_c('span',{staticClass:"job-condition-item",class:{ checked: _vm.currentExperience === '' },on:{"click":function($event){return _vm.handleChange(1, '')}}},[_vm._v("全部")]),_vm._l((_vm.ExperienceOptions),function(ref){
              var label = ref.label;
              var value = ref.value;
return _c('span',{key:value,staticClass:"job-condition-item",class:{
                checked:
                  _vm.currentExperience !== '' && _vm.currentExperience == value
              },on:{"click":function($event){return _vm.handleChange(1, value)}}},[_vm._v(_vm._s(label))])})],2)]),_c('div',{staticClass:"job-condition"},[_c('span',{staticClass:"job-condition-title"},[_vm._v("人才学历:")]),_c('div',{staticClass:"job-condition-wrap"},[_c('span',{staticClass:"job-condition-item",class:{ checked: _vm.currentEducation === '' },on:{"click":function($event){return _vm.handleChange(2, '')}}},[_vm._v("全部")]),_vm._l((_vm.EducationOptions),function(ref){
              var label = ref.label;
              var value = ref.value;
return _c('span',{key:value,staticClass:"job-condition-item",class:{
                checked: _vm.currentEducation !== '' && _vm.currentEducation == value
              },on:{"click":function($event){return _vm.handleChange(2, value)}}},[_vm._v(_vm._s(label))])})],2)])])])]),_c('div',{staticClass:"g-section mb-20"},[_c('div',{staticClass:"g-wrap flex-column p-20",staticStyle:{"border-radius":"0px 0px 8px 8px"}},[_c('div',{staticClass:"talent-list"},[_c('list',{attrs:{"list":_vm.list}}),_c('div',{staticClass:"loading"},[(_vm.loading)?_c('div',[_vm._v("加载中...")]):(_vm.noMore)?_c('div',[_vm._v("—— 没有更多了 ——")]):_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.load}},[_vm._v("加载更多")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }