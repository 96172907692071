<template>
  <div class="job-container">
    <div class="g-section">
      <div class="g-wrap mt-20 p-20" style="border-radius: 8px 8px 0px 0px;">
        <!-- <el-cascader class="mr-10" v-model="searchArea" :options="optionsArea" placeholder="地区"></el-cascader>
        <el-cascader class="mr-10" v-model="searchArea" :options="optionsArea" placeholder="行业"></el-cascader>
        <el-cascader class="mr-10" v-model="searchArea" :options="optionsArea" placeholder="岗位"></el-cascader> -->
        <el-input
          placeholder="请输入关键字搜索"
          v-model="keyword"
          class="mr-10 input-with-select"
        >
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="g-section">
      <div class="g-wrap flex-column pr-20 pl-20 pb-20 border-bottom">
        <div
          :class="
            `${isExpand ? 'condition-section' : 'condition-section-hide'}`
          "
        >
          <div class="job-condition">
            <span class="job-condition-title">薪资要求:</span>
            <div class="job-condition-wrap">
              <span
                class="job-condition-item"
                v-bind:class="{ checked: currentSalary === '' }"
                @click="handleChange(0, '')"
                >全部</span
              >
              <span
                v-for="{ label, value } in SalaryOptions"
                :key="value"
                class="job-condition-item"
                @click="handleChange(0, value)"
                v-bind:class="{
                  checked: currentSalary !== '' && currentSalary == value
                }"
                >{{ label }}</span
              >
            </div>
          </div>
          <div class="job-condition">
            <span class="job-condition-title ">工作经验:</span>
            <div class="job-condition-wrap ">
              <span
                class="job-condition-item"
                v-bind:class="{ checked: currentExperience === '' }"
                @click="handleChange(1, '')"
                >全部</span
              >
              <span
                v-for="{ label, value } in ExperienceOptions"
                :key="value"
                class="job-condition-item"
                @click="handleChange(1, value)"
                v-bind:class="{
                  checked:
                    currentExperience !== '' && currentExperience == value
                }"
                >{{ label }}</span
              >
            </div>
          </div>
          <div class="job-condition">
            <span class="job-condition-title">人才学历:</span>
            <div class="job-condition-wrap">
              <span
                class="job-condition-item"
                v-bind:class="{ checked: currentEducation === '' }"
                @click="handleChange(2, '')"
                >全部</span
              >
              <span
                v-for="{ label, value } in EducationOptions"
                :key="value"
                class="job-condition-item"
                @click="handleChange(2, value)"
                v-bind:class="{
                  checked: currentEducation !== '' && currentEducation == value
                }"
                >{{ label }}</span
              >
            </div>
          </div>
        </div>
        <!-- <div class="job-condition-btn mb-10">
          <el-button
            type="text"
            class="color-primary"
            :icon="`${isExpand ? 'el-icon-arrow-up' : 'el-icon-arrow-down'}`"
            @click="isExpand = !isExpand"
            >{{ isExpand ? "收起" : "更多" }}
          </el-button>
        </div> -->
      </div>
    </div>
    <div class="g-section mb-20">
      <div
        class="g-wrap flex-column p-20"
        style="border-radius: 0px 0px 8px 8px;"
      >
        <!-- <div class="sel-condition">
          <div>
            <span class="sel-condition-title">已选条件:</span>
            <div class="sel-condition-wrap ">
            </div>
          </div>
          <span>共找到<span class="job-title">888+</span>个人才</span>
        </div> -->
        <div class="talent-list">
          <list :list="list"></list>
          <div class="loading">
            <div v-if="loading">加载中...</div>
            <div v-else-if="noMore">—— 没有更多了 ——</div>
            <el-button v-else @click="load" type="text">加载更多</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getPageList } from "@/api/resume";
import { dateTimeFilter } from "@/filters";
import { StaffTypeEnum } from "@/utils/constant";
import List from "@/components/TalentList";
export default {
  components: {
    List
  },
  data() {
    return {
      dialogVisible: false,
      SalaryOptions: [],
      ExperienceOptions: [],
      EducationOptions: [],
      searchArea: [],
      optionsArea: [
        {
          value: "gd",
          label: "广东",
          children: [
            {
              value: "sz",
              label: "深圳",
              children: [
                {
                  value: "lh",
                  label: "龙华"
                },
                {
                  value: "ns",
                  label: "南山"
                },
                {
                  value: "ft",
                  label: "福田"
                },
                {
                  value: "lg",
                  label: "龙岗"
                }
              ]
            },
            {
              value: "gz",
              label: "广州",
              children: [
                {
                  value: "by",
                  label: "白云"
                },
                {
                  value: "th",
                  label: "天河"
                }
              ]
            }
          ]
        },
        {
          value: "hn",
          label: "湖南",
          children: [
            {
              value: "ch",
              label: "长沙",
              children: [
                {
                  value: "qb",
                  label: "全部"
                }
              ]
            }
          ]
        }
      ],
      list: [],
      keyword: "",
      loading: false,
      pageIndex: 0,
      pageSize: 5,
      totalCount: 0,
      Resume: {
        ResumeCareerDtoList: [],
        ResumeExpectDtoList: [],
        ResumeProjectDtoList: []
      },
      isExpand: true,
      currentSalary: "",
      currentExperience: "",
      currentEducation: "",
      UnitId: undefined
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    ...mapState("common", {
      EducationList: state => state.Education,
      ExperienceList: state => state.Experience,
      SalaryList: state => state.Salary
    }),
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    },
    formartDate() {
      return (data, formate) => dateTimeFilter(data, formate);
    }
  },
  mounted() {
    this.init();
    this.getCondition();
  },
  methods: {
    init() {
      const {
        Employer: { Id }
      } =
        this.employee.find(
          ({ StaffType }) => StaffType === StaffTypeEnum.School
        ) || {};
      this.UnitId = Id;
      this.load();
    },
    load() {
      this.loading = true;
      this.pageIndex++;
      var queryData = {
        KeyWord: this.keyword,
        PageSize: this.pageSize,
        PageIndex: this.pageIndex,
        UnitId: this.UnitId
      };
      getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
        this.list = this.list.concat(Data);
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },

    search() {
      this.loading = true;
      this.pageIndex = 1;
      var queryData = {
        KeyWord: this.keyword,
        PageSize: this.pageSize,
        PageIndex: this.pageIndex,
        Salary: this.currentSalary,
        Experience: this.currentExperience,
        Education: this.currentEducation,
        UnitId: this.UnitId
      };
      getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
        this.list = Data;
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    getCondition() {
      //todo:需要修改条件
      this.SalaryOptions = this.SalaryList.map(({ Text, Value }) => ({
        label: Text,
        value: Value
      }));
      this.ExperienceOptions = this.ExperienceList.map(({ Text, Value }) => ({
        label: Text,
        value: Value
      }));
      this.EducationOptions = this.EducationList.map(({ Text, Value }) => ({
        label: Text,
        value: Value
      }));
    },
    handleChange(type, value) {
      let needRefresh = false;
      switch (type) {
        case 0:
          if (this.currentSalary !== value) {
            needRefresh = true;
            this.currentSalary = value;
          }
          break;
        case 1:
          if (this.currentExperience !== value) {
            needRefresh = true;
            this.currentExperience = value;
          }
          break;
        case 2:
          if (this.currentEducation !== value) {
            needRefresh = true;
            this.currentEducation = value;
          }
          break;
        default:
          break;
      }
      if (needRefresh) {
        this.search();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.job-container {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  flex: 1;
  .g-section {
    .g-wrap {
      background: #fff;
    }
  }
}
.job-banner {
  display: flex;
  width: 100%;
  height: 200px;
  background-color: #d3dfe8;
  justify-content: center;
  align-items: center;
}
.border-bottom {
  border-bottom: 1px solid #eee;
}
.condition-section {
  flex-direction: column;
  padding-top: 0px;
}
.talent-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.talent-item {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #eee;
  font-size: 14px;
  color: #999;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  }
}

.item-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.condition-section-hide {
  display: none;
}
.job-condition {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .job-condition-title {
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
  .job-condition-wrap {
    display: flex;
    .job-condition-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 3px;
      margin-right: 10px;
      font-size: 14px;
      &:hover {
        background-color: #598df3;
        color: #ffffff;
      }
    }
    .checked {
      background-color: #598df3;
      color: #ffffff;
    }
  }
}
.job-condition-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sel-condition {
  border: 1px solid #b3d8ff;
  background-color: #ecf5ff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  .sel-condition-title {
    color: #598df3;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
}
.job-title {
  color: #598df3;
  font-size: 18px;
}
.table-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-collect {
  color: #598df3;
  font-size: 18px;
  cursor: pointer;
}
.dialog-content {
  height: 400px;
  display: flex;
  padding: 38px 28px;
  flex-direction: column;
  overflow-y: auto;
  .content-title {
    margin-bottom: 16px;
    &::before {
      content: "";
      vertical-align: -3px;
      border-radius: 2px;
      display: inline-block;
      width: 3px;
      height: 16px;
      background-color: #598df3;
      margin-right: 5px;
    }
  }
}
.loading {
  text-align: center;
}
</style>
